import muiRootWrapper from "./mui-root-wrapper"
import { yandexRotorScript } from "./src/utils"

export const wrapRootElement = muiRootWrapper

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return [0, 0]
}

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.innerHTML = yandexRotorScript;
  document.head.appendChild(script);
};