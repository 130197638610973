const dataLayerHandler = (action, payload, quantity = 1) => {
  var dataLayer = window.dataLayer || []
  var productData
  if (!Array.isArray(payload?.products)) {
    if (payload?.repair) return
    productData = {
      id: payload.uid,
      name: payload.data.name,
      price: payload.data.price,
    }
    if (payload?.data?.color_group) {
      productData["variant"] = payload.data.color_group
    }
    if (payload?.data?.brand?.document?.data?.name) {
      productData["brand"] = payload?.data?.brand?.document?.data?.name
    }
    if (payload?.data?.category?.document?.data?.name) {
      productData["category"] = payload?.data?.category?.document?.data?.name
    }
  }
  switch (action) {
    case "view":
      dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          detail: {
            products: [productData],
          },
        },
      })
      break
    case "add":
      productData["quantity"] = quantity
      dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: [productData],
          },
        },
      })
      break
    case "remove":
      productData["quantity"] = quantity
      dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          remove: {
            products: [productData],
          },
        },
      })
      break
    case "purchase":
      let products = payload.products.map(item => {
        if (item?.repair) return
        var newItem = {
          id: item.product_uid,
          name: item.product_name,
          price: item.price,
          quantity: item.quantity,
          // "brand": item.brand ?? "",
          // "category": item.category ?? "",
          // "variant": item.variant ?? "",
        }
        return newItem
      })
      dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          purchase: {
            actionField: {
              id: payload.orderNum,
            },
            products: products,
          },
        },
      })
      break
    default:
      break
  }
}

export default dataLayerHandler
