import CookieConsent from 'react-cookie-consent';
import React from 'react';


const CookieModal = () => {
  const hideCoockie = () => {
    console.log(document.querySelector('.CookieConsent'));
    document.querySelector('.CookieConsent').classList.add('hiddenCookie');
  };
  
  return (
    <CookieConsent
      id="cookieModal"
      location="bottom"
      buttonText={'Согласен'}
      cookieName="myAwesomeCookieName2"

      buttonStyle={{ color: 'white', fontSize: '13px', backgroundColor: '#291AD5', width: '150px', height: '40px', borderRadius: 12 }}
      style={{
        backgroundColor: '#efeff2',
        color: 'black',
        borderRadius: 12,
        ...(window.innerWidth <= 768 && { margin: '0px 5px 75px 5px' })
      }}
      expires={150}
      // enableDeclineButton
      flipButtons
      // declineButtonText={declineButtonText}
      // declineButtonStyle={{
      //   color: 'white',
      //   fontSize: '13px',
      //   backgroundColor: '#d70606',
      //   width: '150px',
      //   height: '40px',
      // }}
    >
      Продолжая работу с сайтом вы даете согласие на сбор файлов cookie
    </CookieConsent>
  );
};

export default CookieModal;